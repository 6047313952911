
import Vue, { PropType } from "vue";

import { Lesson } from "@prestonly/preston-common";
import { DialogCloseType, DialogI } from "@/types/dialog";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import BaseLessonForm from "@/components/form/BaseLessonForm.vue";

export default Vue.extend({
  name: "CreateLessonDialog",
  components: {
    BaseDialog,
    BaseLessonForm,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  data: function (): {
    lesson: Partial<Lesson>;
  } {
    return {
      lesson: {
        name: "",
        description: "",
        level: undefined,
        active: undefined,
        tags: [],
        targetLang: undefined,
        sourceLang: undefined,
      },
    };
  },
  methods: {
    syncChanges(lesson?: Partial<Lesson>) {
      this.lesson = { ...this.lesson, ...lesson };
    },
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          lesson: this.lesson,
        },
      });
    },
  },
});
